import { LeftOutlined } from "@ant-design/icons"
import React from "react"
import CustomButton, { ButtonColors } from "../ui/button"
import CustomModal from "../ui/modal"
import tw, { css } from "twin.macro"
import { Checkbox, Tooltip } from "antd"
import styled from "@emotion/styled"

// styled tooltip
const StyledTooltip = styled(({ className, ...other }) => (
  <Tooltip overlayClassName={className} {...other} />
))`
  font-size: 0.75rem;
  max-width: 30rem;
  display: block;

  & .ant-tooltip-content {
    width: 30rem;
    display: block;
  }

  & .ant-tooltip-inner {
    /* width: 30rem; */
    color: #000000 !important;
    background-color: #ffffff;
    border: 1px solid #979797;
    line-height: 1.25;
    letter-spacing: 0;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
  }

  & .ant-tooltip-arrow {
    width: 1.625rem;
    height: 1.625rem;
    /* left: 0.5rem; */
    & .ant-tooltip-arrow-content {
      box-shadow: 0 2px 8px rgb(0 0 0 / 3%);
      width: 1rem;
      height: 1rem;
      background-color: #ffffff;
      border-right: 1px solid #979797;
      border-bottom: 1px solid #979797;
      top: 0.7rem;
    }
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Footer = ({
  heading,
  onSuccess,
  isSubmitActive,
  showBackIcon,
  label,
  showTCCheckbox,
  onChecked,
}: {
  heading: string
  onSuccess: () => void
  isSubmitActive: boolean
  showBackIcon: boolean
  label: string
  showTCCheckbox?: boolean
  onChecked?: (e: boolean) => void
}) => {
  return (
    <CustomModal.Footer heading={heading}>
      <div tw="flex items-center justify-end py-4">
        {showTCCheckbox && onChecked && (
          <Checkbox
            onChange={e => onChecked(e.target.checked)}
            css={css`
              & .ant-checkbox-checked {
                & .ant-checkbox-inner {
                  background-color: #000000;
                  border-color: #000000;
                }
              }
              & .ant-checkbox:hover .ant-checkbox-inner {
                border-color: #000000;
              }
              & .ant-checkbox-input:focus + .ant-checkbox-inner {
                border-color: #000000;
              }
              & :hover .ant-checkbox-inner {
                border-color: #000000;
              }
            `}
            tw="cursor-text"
          >
            I understand and acknowledge the{" "}
            <StyledTooltip
              placement="top"
              title={
                <span>
                  As a reminder, Section 13 of your signed 4PatientCare contract
                  contains language indemnifying 4PatientCare of any misuse of
                  this tool or patient data.{" "}
                  <strong>
                    Specifically of note, Patient IDs must align to actual
                    Patient IDs in your PM system or your organization risks
                    violation of the CAN-SPAM Act (in addition to state level
                    legislation) as 4PatientCare will no longer be able to
                    properly manage &#34;unsubscribes&#34; and suppress future
                    messages.
                  </strong>{" "}
                  Please reference your 4PatientCare contract for additional
                  Terms and Conditions information.
                </span>
              }
              css={tw`ml-1`}
              arrowPointAtCenter
              destroyTooltipOnHide
            >
              <span tw="font-bold text-blue-600 cursor-default">
                terms and conditions
              </span>
            </StyledTooltip>{" "}
            of using the eBlast tool.
          </Checkbox>
        )}
        <CustomButton
          disabled={!isSubmitActive}
          color={!isSubmitActive ? ButtonColors.darkGray : ButtonColors.orange}
          onClick={onSuccess}
          customCss={
            !isSubmitActive
              ? tw`font-bold text-opacity-50! min-w-12 block! text-center`
              : tw`font-bold min-w-12 block! text-center`
          }
        >
          {showBackIcon && <LeftOutlined tw="mr-2" />}
          {label}
        </CustomButton>
      </div>
    </CustomModal.Footer>
  )
}
