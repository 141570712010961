import React from "react"
import tw, { css } from "twin.macro"

import { CampaignStatus } from "@src/queries/campaign"

const statusDetails = {
  [CampaignStatus.Draft]: {
    label: "Draft",
    style: tw`bg-gray-500`,
  },
  [CampaignStatus.Submitted]: {
    label: "Submitted",
    style: tw`bg-black`,
  },
  [CampaignStatus.Ran]: {
    label: "Delivered",
    style: tw`bg-green-500`,
  },
}

interface CampaignStatusProps {
  status: CampaignStatus
}

const Status = ({ status }: CampaignStatusProps): React.ReactElement => (
  <div
    css={[
      tw`text-white text-xs uppercase`,
      css`
        padding: 0.1rem 1.25rem;
      `,
      statusDetails[status].style,
    ]}
  >
    {statusDetails[status].label}
  </div>
)

export default Status
