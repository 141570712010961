import React from "react"

import Layout from "@src/components/layout"
import Campaigns from "@src/sections/campaigns"

const Index = (): React.ReactElement => {
  return (
    <Layout>
      <Campaigns />
    </Layout>
  )
}

export default Index
