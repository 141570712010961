// const catchErrors = ({ graphQLErrors, networkError }) => {
//     console.log("graphQLErrors", graphQLErrors)
//     console.log("networkError", networkError)
//     if (graphQLErrors && graphQLErrors.length > 0) {
//       if (typeof graphQLErrors === "object" && !Array.isArray(graphQLErrors)) {
//         Object.values(graphQLErrors).forEach(val =>
//           console.log(`[GraphQL error]: Message: ${val}`)
//         )
//       }
//     }
//     if (networkError) console.log(`[Network error]: ${networkError}`)
//   }
// )

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getFormErrors = (e: any): string[] => {
  let errors: string[] = []
  if ("errorFields" in e && e.errorFields && e.errorFields.length > 0) {
    e.errorFields.map((oneError: any) => {
      if (oneError && oneError.errors && oneError.errors.length > 0) {
        errors = [
          ...errors,
          ...oneError.errors as string[]
        ] as unknown as string[]
      }
    })
  }
  return errors;
}