import React from "react"
import tw, { css } from "twin.macro"
import { navigate } from "gatsby"
import { RouteComponentProps } from "@reach/router"
import { useQuery, useMutation, useLazyQuery } from "@apollo/client"
import {
  CheckCircleFilled,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons"
import moment from "moment-timezone"
import {
  DatePicker,
  Form,
  Input,
  Table,
  Tag,
  TimePicker,
  Tooltip,
  Upload,
} from "antd"
import { useToasts } from "react-toast-notifications"

import CustomButton, { ButtonColors } from "@src/components/ui/button"
import CustomModal from "@src/components/ui/modal"
import {
  navigationRoutes,
  PageOptions,
} from "@src/localization/en/createCampaign"
import Loading from "@src/components/loading"
import {
  GET_CAMPAIGN,
  SUBMIT_CAMPAIGN,
  Campaign,
  Segment,
  UPDATE_CAMPAIGN,
  UPLOAD_PATIENT_FILE,
  UploadCsvInput,
  ValidateLocalPatientDataInput,
  VALIDATE_LOCAL_PATIENT_DATA,
} from "@src/queries/campaign"
import { createUrl } from "@src/utils/createUrl"
import { Localization } from "@src/localization"
import InputLabel from "@src/components/ui/inputLabel"
import CustomToolTip from "@src/components/ui/customToolTip"
import { RemainingCampaigns, REMAINING_CAMPAIGNS } from "@src/queries/email"
import UsedAllCampaigns, {
  SubmitErrorType,
} from "@src/components/usedAllCampaigns"
import { GET_TEMPLATE, Template } from "@src/queries/template"
import {
  clientToServer,
  getClientTZAbbreviation,
  setClientTz,
} from "@src/utils/timezone"
import config from "@src/config"
import styled from "@emotion/styled"
import IcDragHandle from "@src/assets/icDragHandle.svg"
import {
  DragDropContext,
  Draggable,
  DraggableLocation,
  Droppable,
  DropResult,
} from "react-beautiful-dnd"
import { Footer } from "@src/components/campaign/customModalFooter"
import _, { uniqBy, filter } from "lodash"

// current date time
const currentDateTime = clientToServer(moment().tz(config.clientTZ))

// combine date & time fields
const combineDateTime = (
  runAtDate: moment.Moment,
  runAtTime: moment.Moment
): string => {
  return `${runAtDate.format("YYYY-MM-DD")}T${runAtTime.format("HH:mm:ss")}`
}

// styled tooltip
const StyledTooltip = styled(({ className, ...other }) => (
  <Tooltip overlayClassName={className} {...other} />
))`
  font-size: 0.75rem;
  width: 19rem;

  & .ant-tooltip-inner {
    width: 19rem;
    color: #000000 !important;
    background-color: #ffffff;
    border: 1px solid #979797;
    line-height: 1.25;
    letter-spacing: 0;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
  }

  & .ant-tooltip-arrow {
    width: 1.625rem;
    height: 1.625rem;
    left: 0.5rem;
    & .ant-tooltip-arrow-content {
      box-shadow: 0 2px 8px rgb(0 0 0 / 3%);
      width: 1rem;
      height: 1rem;
      background-color: #ffffff;
      border-right: 1px solid #979797;
      border-bottom: 1px solid #979797;
      top: 0.7rem;
    }
  }
`

const Title = ({ heading }: { heading: string }) => (
  <CustomModal.Title heading={heading}> </CustomModal.Title>
)

interface CustomModalProps {
  campaignId?: number
}

export enum CsvFormat {
  csv = "text/csv",
}

// eslint-disable-next-line no-empty-pattern
const SubmitCampaignModal = ({
  campaignId,
}: CustomModalProps & RouteComponentProps): React.ReactElement => {
  const { addToast } = useToasts()

  // file extension invalid
  const [fileError, setFileError] = React.useState<string>("")

  // uploaded file name
  const [fileUpload, setFileUpload] = React.useState<File | null>(null)

  // is form valid
  const [isFormValid, setIsFormValid] = React.useState<boolean>(false)

  // page content
  const submitContent = React.useContext(Localization).submitCampaign

  // submit campaign
  const [submitCampaignMutation, submitCampaignResp] = useMutation(
    SUBMIT_CAMPAIGN
  )

  // update campaign
  const [updateCampaignMutation, updateCampaignResp] = useMutation(
    UPDATE_CAMPAIGN
  )

  // drag / drop states
  const [dragDropStates, setDragDropStates] = React.useState<{
    [key: string]: any[]
  }>({})

  // get campaign
  const getCampaignResp = useQuery(GET_CAMPAIGN, {
    variables: {
      campaignId,
    },
    skip: !campaignId,
  })

  // upload csv file
  const [uploadCsvMutation, uploadCsvResp] = useMutation(UPLOAD_PATIENT_FILE)

  // validate csv mapping
  const [validateLocalPatientMutation, validateLocalPatientResp] = useMutation(
    VALIDATE_LOCAL_PATIENT_DATA
  )

  // Parsing csv data to table
  const [csvColumns, setCsvColumns] = React.useState<any[]>([])
  const [csvData, setCsvData] = React.useState<any[]>([])
  const [tableHeader, setTableHeader] = React.useState<any[]>([])
  const [campaignVariables, setCampaignVariables] = React.useState<string[]>([])

  // check is validated
  const [isValidated, setIsValidated] = React.useState<boolean>(false)
  const [localValidatedError, setLocalValidatedError] = React.useState<
    string[] | boolean
  >(false)

  // check enable validate button
  const [isEnableValidate, setIsEnableValidate] = React.useState<boolean>(false)

  // check enable to confirm & send
  const [agreed, setAgreed] = React.useState<boolean>(false)

  const getListStyle = () => ({
    background: "none",
    paddingTop: 0,
    marginTop: 0,
    width: "100%",
    height: 35,
  })

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const move = (
    source: any[],
    destination: any[],
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation
  ) => {
    const sourceClone = Array.from(source)
    const destClone: DraggableLocation[] = droppableDestination.droppableId.includes(
      "templateVariables"
    )
      ? Array.from(destination)
      : Array.from(destination).filter(item => item.isHeader)

    const [removed] = sourceClone.splice(droppableSource.index, 1)

    const notDestHeaderItems = dragDropStates[
      droppableDestination.droppableId
    ].filter(item => !("isHeader" in item))

    destClone.splice(droppableDestination.index, 0, removed)

    const result: any = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone

    result["updateTemplateVariables"] = notDestHeaderItems

    return result
  }

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result
    // dropped outside the list
    if (!destination) {
      return
    }
    const sInd = source.droppableId
    const dInd = destination.droppableId

    if (sInd === dInd) {
      const items = reorder(
        dragDropStates[sInd],
        source.index,
        destination.index
      )
      const newState = { ...dragDropStates }
      newState[sInd] = items
      setDragDropStates(newState)
    } else {
      const result = move(
        dragDropStates[sInd],
        dragDropStates[dInd],
        source,
        destination
      )
      const newState = { ...dragDropStates }
      newState[sInd] = result[sInd]
      newState[dInd] = result[dInd]
      if (sInd === "templateVariables") {
        newState[sInd] = [...result[sInd], ...result["updateTemplateVariables"]]
      } else if (dInd !== "templateVariables") {
        newState["templateVariables"] = [
          ...dragDropStates["templateVariables"],
          ...result["updateTemplateVariables"],
        ]
      }

      setDragDropStates(newState)
      setIsValidated(false)
      setAgreed(false)
    }
  }

  React.useEffect(() => {
    if (tableHeader.length) {
      let tmpIsEnableValidate = false
      const tmpCsvColumns: any[] = []
      tableHeader.forEach((header: string, index: number) => {
        if (
          header in dragDropStates &&
          dragDropStates[header].filter(item => !("isHeader" in item)).length
        ) {
          tmpIsEnableValidate = true
        }
        tmpCsvColumns.push({
          title: (
            <Droppable key={header} droppableId={header}>
              {provided => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle()}
                  {...provided.droppableProps}
                >
                  {dragDropStates &&
                    header in dragDropStates &&
                    dragDropStates[header].map((item: any, idx: number) => (
                      <div key={item.id}>
                        {item.isHeader &&
                          dragDropStates[header].length === 1 && (
                            <span tw="leading-8 h-8 block whitespace-no-wrap">
                              {item.content}
                            </span>
                          )}
                        {!item.isHeader && (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={idx}
                            tw="mt-1"
                          >
                            {provided => (
                              <Tag
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                color="#f27a00"
                                css={tw`h-2.18725 leading-2.7 inline-flex items-center pr-0`}
                              >
                                {item.content}
                                <IcDragHandle style={{ width: "2rem" }} />
                              </Tag>
                            )}
                          </Draggable>
                        )}
                      </div>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ),
          dataIndex: `col-${index}`,
          key: index,
        })
        setCsvColumns(tmpCsvColumns)
      })
      setIsEnableValidate(tmpIsEnableValidate)
    }
  }, [tableHeader, dragDropStates])

  React.useEffect(() => {
    if (
      uploadCsvResp &&
      uploadCsvResp.data &&
      uploadCsvResp.data.csv &&
      uploadCsvResp.data.csv.CsvRows &&
      Object.keys(dragDropStates).length === 1 &&
      !uploadCsvResp.loading &&
      !uploadCsvResp.error
    ) {
      setFileError("")

      if (isValidated) {
        setIsValidated(false)
        setAgreed(false)
      }

      if (localValidatedError) {
        setLocalValidatedError(false)
      }

      const rowIndex: string[] = []
      const tmpTblHeader: any[] = []

      if (
        uploadCsvResp.data.csv.CsvRows &&
        uploadCsvResp.data.csv.CsvRows.length
      ) {
        uploadCsvResp.data.csv.CsvRows[0].forEach(
          (rowData: string, index: number) => {
            tmpTblHeader.push([rowData, index])
            rowIndex.push(`col-${index}`)
          }
        )
      }

      setTableHeader(tmpTblHeader)
      const tmpStates = { ...dragDropStates }
      tmpTblHeader.map(
        (item, i) =>
          (tmpStates[item] = [
            {
              id: tmpTblHeader[i],
              content: item[0],
              isHeader: true,
            },
          ])
      )

      setDragDropStates(tmpStates)
      const numbers: number[] = [1, 2, 3]

      setCsvData(
        numbers.map((n: number) => {
          const tmpRowData: any = {}
          uploadCsvResp.data.csv.CsvRows[n] &&
            uploadCsvResp.data.csv.CsvRows[n].forEach(
              (rowData: string, index: number) => {
                tmpRowData[rowIndex[index]] = rowData
              }
            )
          tmpRowData.key = `rowData-${n}`
          return tmpRowData
        })
      )
    }
  }, [uploadCsvResp])

  React.useEffect(() => {
    if (
      getCampaignResp &&
      getCampaignResp.data &&
      getCampaignResp.data.campaign &&
      getCampaignResp.data.campaign.templateContent &&
      !("templateVariables" in dragDropStates) &&
      !getCampaignResp.loading &&
      !getCampaignResp.error
    ) {
      const matchTemplateVariables = getCampaignResp.data.campaign.templateContent.match(
        new RegExp(/{{[{]?(.*?)[}]?}}/gm)
      )
      let tmpTemplateVariables = []

      if (matchTemplateVariables && matchTemplateVariables.length) {
        tmpTemplateVariables = matchTemplateVariables
          .map((item: string) => {
            const reg1 = /{{[{]?(.*?)[}]?}}/
            const match = reg1.exec(item)
            return match && match[1].trim()
          })
          .map((item: string) => {
            return {
              id: item,
              content: item === "PATIENT.ID" ? "PATIENT.ID" : item,
            }
          })
      }

      // Remove campaign.id
      // make sure patient.id, patient.email, location.email, provider.phone is alway exists in variables
      const tplVariables = filter(
        uniqBy(
          [
            ...tmpTemplateVariables,
            ...[
              {
                id: "PATIENT.ID",
                content: "PATIENT.ID",
              },
              {
                id: "PATIENT.EMAIL",
                content: "PATIENT.EMAIL",
              },
            ],
          ],
          "id"
        ),
        x => !["CAMPAIGN.ID", "PRACTICE.COVERKEY"].includes(x.id)
      )

      setCampaignVariables(tplVariables)
      setDragDropStates({
        ...dragDropStates,
        templateVariables: tplVariables,
      })
    }
  }, [getCampaignResp])

  const uploadProps = {
    accept: CsvFormat.csv,
    showUploadList: false,
    disabled: uploadCsvResp && uploadCsvResp.loading,
    beforeUpload: (file: File) => {
      const splitName = file.name.split(".")
      const isAllowed = !(
        CsvFormat.csv !== file.type && splitName[splitName.length - 1] !== "csv"
      )
      if (!isAllowed) {
        setFileError(submitContent.uploadFileTypeError)
      }
      return false
    },
    onChange: async (info: any) => {
      const splitName = info.file.name.split(".")
      if (
        CsvFormat.csv !== info.file.type &&
        splitName[splitName.length - 1] !== "csv"
      ) {
        return false
      }
      try {
        setFileUpload(info.file)
        const keys = Object.keys(dragDropStates)
        if (keys.length > 1) {
          setDragDropStates({
            templateVariables: campaignVariables,
          })
        }
        const uploadCsvBody: UploadCsvInput = {
          File: info.file,
        }

        await uploadCsvMutation({
          variables: {
            body: uploadCsvBody,
          },
        })

        return false
      } catch (e) {
        console.warn("ERR", e)
        if (
          e.networkError &&
          e.networkError.result &&
          e.networkError.result.Message
        ) {
          setFileError(e.networkError.result.Message)
        } else {
          setFileError(submitContent.uploadNoContentError)
        }

        throw e
      }
    },
  }

  // campaign
  const campaign: Campaign | null =
    (getCampaignResp &&
      getCampaignResp.data &&
      (getCampaignResp.data.campaign as Campaign)) ||
    null

  // get template
  const getTemplateResp = useQuery(GET_TEMPLATE, {
    variables: {
      templateId: campaign?.selectedTemplateId,
    },
    skip: !campaign,
  })

  // template
  const template: Template | null =
    (getTemplateResp &&
      getTemplateResp.data &&
      (getTemplateResp.data.template as Template)) ||
    null

  // remaining campaigns that user can send
  const [remainingCampaignsQuery, remainingCampaignsResp] = useLazyQuery(
    REMAINING_CAMPAIGNS
  )
  const remainingData: RemainingCampaigns | null =
    remainingCampaignsResp &&
    remainingCampaignsResp.data &&
    remainingCampaignsResp.data.remaining
      ? (remainingCampaignsResp.data.remaining as RemainingCampaigns)
      : null
  const hasUsedAllEmails = remainingData && remainingData.remaining < 1
  // form
  const [form] = Form.useForm()

  // submit campaign
  const submitCampaign = async (submitBody?: {
    uploadedPatientFileId: number
    localPatientMapping: string
  }) => {
    if (!campaign) return

    // update campaign
    await submitCampaignMutation({
      variables: {
        campaignId: campaign.id,
        body: submitBody || {},
      },
      refetchQueries: [
        {
          query: GET_CAMPAIGN,
          variables: { campaignId: campaign.id },
        },
      ],
    })
  }

  // update campaign
  const updateCampaign = async ({ campaign }: { campaign: Campaign }) => {
    // update campaign
    await updateCampaignMutation({
      variables: {
        campaignId: campaign.id,
        body: {
          ...campaign,
        },
      },
    })
  }

  // show loading if no campaign
  if (!campaign) return <Loading withOverlay={true} />

  let noLocalPatients = false
  if (
    uploadCsvResp &&
    uploadCsvResp.data &&
    uploadCsvResp.data.csv &&
    uploadCsvResp.data.csv.CsvCount &&
    validateLocalPatientResp &&
    validateLocalPatientResp.data &&
    validateLocalPatientResp.data.validatePatientData &&
    validateLocalPatientResp.data.validatePatientData.errors
  ) {
    noLocalPatients =
      uploadCsvResp.data.csv.CsvCount -
        validateLocalPatientResp.data.validatePatientData.errors ===
      0
  }

  // is fetching patients count
  const isFetchingPatientsCount = campaign.isFetchingPatientsCount
  const noPatientsFound =
    (campaign.patientsCount === 0 && !campaign.isUsingLocalPatients) ||
    noLocalPatients

  // error type
  let errorType: SubmitErrorType | null = null
  if (isFetchingPatientsCount) {
    errorType = SubmitErrorType.fetchingPatientsCount
  } else if (noPatientsFound) {
    errorType = SubmitErrorType.noRecipients
  } else if (hasUsedAllEmails) {
    errorType = SubmitErrorType.usedAllCampaigns
  }

  return (
    <>
      {(getCampaignResp.loading ||
        remainingCampaignsResp.loading ||
        submitCampaignResp.loading ||
        updateCampaignResp.loading) && <Loading withOverlay={true} />}
      <CustomModal
        title={<Title heading={submitContent.heading} />}
        isVisible={true}
        onCancel={() => navigate(navigationRoutes[PageOptions.CAMPAIGNS])}
        footer={
          <>
            {campaign && !campaign.isUsingLocalPatients && (
              <>
                {errorType && (
                  <UsedAllCampaigns bgColor="red" errorType={errorType} />
                )}

                <Footer
                  heading={""}
                  isSubmitActive={
                    (isFormValid &&
                      campaign &&
                      !errorType &&
                      !!remainingData &&
                      agreed) ||
                    errorType === SubmitErrorType.noRecipients
                  }
                  label={
                    errorType === SubmitErrorType.noRecipients
                      ? "UPDATE SEGMENTATION"
                      : "CONFIRM & SUBMIT"
                  }
                  showBackIcon={errorType === SubmitErrorType.noRecipients}
                  onSuccess={async () => {
                    // update segmentation route
                    if (errorType === SubmitErrorType.noRecipients) {
                      campaignId &&
                        navigate(
                          createUrl(
                            navigationRoutes[
                              PageOptions.EDITCAMPAIGNPICKSEGMENT
                            ],
                            {
                              campaignId,
                            }
                          )
                        )
                      return
                    }

                    // submit form
                    try {
                      // values
                      const values = await form.validateFields()

                      // update campaign
                      await updateCampaign({
                        campaign: {
                          ...campaign,
                          subject: values.subject,
                          runAt: (clientToServer(
                            combineDateTime(values.runAtDate, values.runAtTime)
                          ).format(
                            "YYYY-MM-DDTHH:mm:ss.SSS"
                          ) as unknown) as Date,
                        },
                      })

                      // submit campaign
                      await submitCampaign()

                      campaignId &&
                        navigate(
                          createUrl(
                            navigationRoutes[PageOptions.SUBMITTEDCAMPAIGN],
                            {
                              campaignId,
                            }
                          )
                        )
                    } catch (e) {
                      console.error(e)
                      addToast(e.message || "Something went wrong", {
                        appearance: "error",
                        autoDismiss: true,
                      })
                    }
                  }}
                  showTCCheckbox={errorType !== SubmitErrorType.noRecipients}
                  onChecked={setAgreed}
                />
              </>
            )}
            {campaign &&
              campaign.isUsingLocalPatients &&
              uploadCsvResp &&
              uploadCsvResp.data && (
                <>
                  {errorType && (
                    <UsedAllCampaigns bgColor="red" errorType={errorType} />
                  )}

                  {validateLocalPatientResp.data && isValidated && (
                    <UsedAllCampaigns
                      bgColor={
                        validateLocalPatientResp.data &&
                        validateLocalPatientResp.data.validatePatientData
                          .errors === 0
                          ? "green"
                          : "red"
                      }
                      errorType={SubmitErrorType.validateLocalPatientData}
                      errorData={
                        validateLocalPatientResp.data &&
                        validateLocalPatientResp.data.validatePatientData
                      }
                    />
                  )}
                  {localValidatedError && _.isArray(localValidatedError) && (
                    <UsedAllCampaigns
                      bgColor="red"
                      errorType={SubmitErrorType.localValidatedError}
                      templateVariables={localValidatedError}
                    />
                  )}
                  {/* Footer sections */}
                  {!isValidated && (
                    <Footer
                      heading={" "}
                      isSubmitActive={
                        isEnableValidate || !validateLocalPatientResp.loading
                      }
                      label={submitContent.validateDataBtn}
                      showBackIcon={false}
                      onSuccess={async () => {
                        // local validation
                        if (dragDropStates["templateVariables"].length) {
                          setLocalValidatedError(
                            dragDropStates["templateVariables"].map(x => x.id)
                          )
                          return
                        } else {
                          setLocalValidatedError(false)
                        }

                        // validate
                        const parsingData = new Map(
                          Object.keys(dragDropStates)
                            .filter(key => key !== "templateVariables")
                            .map(key => {
                              const mappingVar = dragDropStates[key]
                                .filter(item => !("isHeader" in item))
                                .map(item => item.id)

                              return [mappingVar[0], key.split(",")[1]]
                            })
                        )

                        try {
                          const validateDataInput: ValidateLocalPatientDataInput = {
                            uploadedPatientFileId:
                              uploadCsvResp.data.csv.UploadedPatientFile.Id,
                            localPatientMapping: Object.fromEntries(
                              parsingData
                            ),
                          }

                          await validateLocalPatientMutation({
                            variables: {
                              body: {
                                ...validateDataInput,
                              },
                            },
                          })
                          if (!isValidated) {
                            setIsValidated(!isValidated)
                          }
                        } catch (e) {
                          console.log(e)
                        }
                      }}
                    />
                  )}
                  {isValidated && (
                    <Footer
                      heading={" "}
                      isSubmitActive={
                        isFormValid &&
                        campaign &&
                        !errorType &&
                        !!remainingData &&
                        agreed
                      }
                      label={"CONFIRM & SEND"}
                      showBackIcon={false}
                      onSuccess={async () => {
                        try {
                          // values
                          const values = await form.validateFields()

                          // update campaign
                          await updateCampaign({
                            campaign: {
                              ...campaign,
                              subject: values.subject,
                              runAt: (clientToServer(
                                combineDateTime(
                                  values.runAtDate,
                                  values.runAtTime
                                )
                              ).format(
                                "YYYY-MM-DDTHH:mm:ss.SSS"
                              ) as unknown) as Date,
                            },
                          })

                          // submit campaign
                          const parsingData = new Map(
                            Object.keys(dragDropStates)
                              .filter(key => key !== "templateVariables")
                              .map(key => {
                                const mappingVar = dragDropStates[key]
                                  .filter(item => !("isHeader" in item))
                                  .map(item => item.id)

                                return [mappingVar[0], key.split(",")[1]]
                              })
                          )
                          const submitBody = {
                            uploadedPatientFileId:
                              uploadCsvResp.data.csv.UploadedPatientFile.Id,
                            localPatientMapping: JSON.stringify(
                              Object.fromEntries(parsingData)
                            ),
                          }

                          await submitCampaign(submitBody)

                          campaignId &&
                            navigate(
                              createUrl(
                                navigationRoutes[PageOptions.SUBMITTEDCAMPAIGN],
                                {
                                  campaignId,
                                }
                              )
                            )
                        } catch (e) {
                          console.error(e)
                          addToast(e.message || "Something went wrong", {
                            appearance: "error",
                            autoDismiss: true,
                          })
                        }
                      }}
                      showTCCheckbox={true}
                      onChecked={setAgreed}
                    />
                  )}
                </>
              )}
          </>
        }
        width={900}
      >
        <div tw="flex flex-col px-16 pt-4 pb-4 text-black">
          <p
            css={[tw`text-sm`]}
            dangerouslySetInnerHTML={{
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              __html: submitContent.description,
            }}
          ></p>

          <div css={[tw`flex mt-6 font-bold text-base`]}>
            {submitContent.campaign}: {campaign.name}
          </div>
          <div tw="text-sm font-bold">
            {submitContent.template} 1: {template ? template.name : "-"}
          </div>

          <div tw="flex flex-col text-black w-full text-xs pt-4">
            {/* patient segment*/}
            {campaign && !campaign.isUsingLocalPatients && (
              <div tw="flex items-center pt-1">
                <CheckCircleFilled />
                <span tw="ml-2">
                  {submitContent.marketSegment}:{" "}
                  <span tw="font-bold">
                    {campaign.segment === Segment.Age && (
                      <span>
                        Age Range - {campaign.minAge} to {campaign.maxAge}
                      </span>
                    )}

                    {campaign.segment === Segment.Gender && (
                      <span>Gender: {campaign.gender}</span>
                    )}

                    {campaign.segment === Segment.AppointmentDate && (
                      <span>
                        Appointment Date Range -{" "}
                        {moment(campaign.minAppointmentDate).format(
                          "MM/DD/YYYY"
                        )}{" "}
                        -{" "}
                        {moment(campaign.maxAppointmentDate).format(
                          "MM/DD/YYYY"
                        )}
                      </span>
                    )}

                    {campaign.segment === Segment.PurchasedEyewear && (
                      <span>
                        Purchased Eyewear in - {campaign.purchasedEyewearIn}{" "}
                        days
                      </span>
                    )}

                    {campaign.segment === Segment.All && <span>All</span>}
                  </span>
                </span>
              </div>
            )}

            {/* audience */}
            {campaign && !campaign.isUsingLocalPatients && (
              <div tw="flex items-center pt-1 pb-2">
                <CheckCircleFilled />
                <span tw="ml-2 mr-2">
                  {submitContent.segmentRecipients}:{" "}
                  <span
                    css={[
                      tw`font-bold`,
                      errorType &&
                        [
                          SubmitErrorType.noRecipients,
                          SubmitErrorType.fetchingPatientsCount,
                        ].includes(errorType) &&
                        tw`text-red-700`,
                    ]}
                  >
                    {errorType === SubmitErrorType.fetchingPatientsCount
                      ? "Recipients checklist is running..."
                      : `${campaign.patientsCount || "0"} ${
                          submitContent.activeRecipients
                        }`}
                  </span>
                </span>
                {errorType !== SubmitErrorType.fetchingPatientsCount && (
                  <CustomToolTip
                    title="Active patients are those that have had an appointment with your practice within the past 36 months."
                    placement="top"
                    customCss={tw`pt-0!`}
                  />
                )}
              </div>
            )}

            {/* patient segment - csv upload */}
            {campaign && campaign.isUsingLocalPatients && (
              <>
                <div tw="flex items-center">
                  <StyledTooltip
                    placement="topLeft"
                    title={submitContent.uploadCsvTooltip}
                    css={tw`ml-1`}
                    arrowPointAtCenter
                  >
                    <InfoCircleOutlined css={tw`text-blue-600`} />
                  </StyledTooltip>

                  <span tw="flex items-center ml-2">
                    {submitContent.uploadPatientRecipientList}
                    <Upload {...uploadProps} tw="ml-3">
                      <CustomButton
                        color={ButtonColors.blue}
                        customCss={tw`font-bold text-xs`}
                      >
                        {uploadCsvResp && uploadCsvResp.loading && (
                          <LoadingOutlined />
                        )}
                        {uploadCsvResp && uploadCsvResp.loading
                          ? submitContent.uploadingCSVFile
                          : submitContent.uploadCSVFile}
                      </CustomButton>
                    </Upload>
                    {uploadCsvResp && uploadCsvResp.data && !fileError && (
                      <>
                        <span tw="ml-2 text-green-700 font-semibold">
                          {submitContent.uploadSuccess}
                        </span>
                        <span tw="ml-2 whitespace-no-wrap w-40 truncate overflow-hidden">
                          {fileUpload && fileUpload.name}
                        </span>
                      </>
                    )}
                    {fileError && (
                      <span tw="ml-2 text-red-700 font-semibold">
                        {fileError}
                      </span>
                    )}
                  </span>
                </div>
                {/* Drag - Drop patient ID badges */}
                {uploadCsvResp && uploadCsvResp.data && (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <div tw="flex items-center mt-3 min-w-full block">
                      <span tw="flex font-bold text-2xs">
                        <span tw="w-2.2/5 mt-2">
                          {submitContent.dragAndPlaceIDBadges}
                        </span>
                        <Droppable
                          key="templateVariables"
                          droppableId="templateVariables"
                        >
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              tw="flex items-center w-3full flex-wrap"
                            >
                              {dragDropStates &&
                                dragDropStates.templateVariables.length > 0 &&
                                dragDropStates.templateVariables.map(
                                  (item: any, idx: number) => (
                                    <Draggable
                                      key={item.id}
                                      draggableId={item.id}
                                      index={idx}
                                      tw="mt-1"
                                    >
                                      {provided => (
                                        <Tag
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          color="#f27a00"
                                          css={tw`h-2.18725 leading-2.7 flex items-center pr-0 mt-2`}
                                        >
                                          {item.content}
                                          <IcDragHandle
                                            style={{ width: "2rem" }}
                                          />
                                        </Tag>
                                      )}
                                    </Draggable>
                                  )
                                )}

                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </span>
                    </div>
                    {isValidated && (
                      <div tw="font-bold text-right mt-5">
                        {uploadCsvResp.data.csv.CsvCount > 0 &&
                          uploadCsvResp.data.csv.CsvCount -
                            validateLocalPatientResp.data.validatePatientData
                              .errors}{" "}
                        out of{" "}
                        {uploadCsvResp.data.csv.CsvCount > 0 &&
                          uploadCsvResp.data.csv.CsvCount}{" "}
                        recipients in your CSV list have properly formatted data
                        and are ready to receive your eblast.
                      </div>
                    )}
                    <div tw="flex items-center border-solid border-t border-b border-gray-500 border-l-0 border-r-0 -mx-2 px-2 mt-3">
                      {csvColumns && csvData && (
                        <Table
                          columns={csvColumns}
                          dataSource={csvData}
                          pagination={false}
                          tableLayout="auto"
                          scroll={{ x: "100%" }}
                          css={[
                            css`
                              width: 100%;
                              & .ant-table-content {
                                & table {
                                  border: none;
                                  border-left: 1px solid #979797;
                                  & td {
                                    border: none;
                                    border-right: 1px solid #979797;
                                    padding: 0.625rem;
                                  }
                                  & thead {
                                    & th {
                                      border-right: 1px solid #979797;
                                      border-bottom: 1px solid #979797;
                                      font-weight: bold;
                                      padding: 0.2rem 0.625rem;
                                    }
                                  }
                                }
                              }
                            `,
                          ]}
                        />
                      )}
                    </div>
                  </DragDropContext>
                )}
              </>
            )}

            <Form
              name="submitCampaign"
              form={form}
              initialValues={campaign}
              onValuesChange={async changedValues => {
                try {
                  await form.validateFields()
                  setIsFormValid(true)
                } catch (e) {
                  console.error(e)
                  setIsFormValid(e.errorFields.length > 0 ? false : true)
                }

                // values
                const values = form.getFieldsValue()

                // if release Date or Time changes, refetch remaining campaigns
                if (
                  values &&
                  values.runAtDate &&
                  values.runAtTime &&
                  form.getFieldError("runAtDate").length === 0 &&
                  form.getFieldError("runAtTime").length === 0 &&
                  ("runAtDate" in changedValues || "runAtTime" in changedValues)
                ) {
                  remainingCampaignsQuery({
                    variables: {
                      runAt: (clientToServer(
                        combineDateTime(values.runAtDate, values.runAtTime)
                      ).format("YYYY-MM-DDTHH:mm:ss.SSS") as unknown) as Date,
                    },
                  })
                }
              }}
              css={css`
                & .ant-select-selector,
                & .ant-input,
                & .ant-input-number,
                & .ant-input-affix-wrapper,
                & .ant-picker,
                & .ant-select-selector,
                & .ant-picker-input > input {
                  border-radius: 50px !important;
                  font-size: 0.75rem;
                }
                & .ant-row.ant-form-item {
                  margin-bottom: 0px !important;
                }
              `}
            >
              {/* run date */}
              <div tw="flex flex-row mt-3 w-full items-center">
                <div tw="w-6/12 flex flex-row items-center">
                  <InputLabel
                    isRequired
                    customCss={tw`w-6/12 font-bold text-2xs!`}
                  >
                    {submitContent.runDate}
                  </InputLabel>
                  <div tw="flex items-center w-6/12">
                    <Form.Item
                      name="runAtDate"
                      tw="w-full"
                      dependencies={["runAtTime"]}
                      rules={[
                        {
                          required: true,
                          message: "Please input!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_rule, value) {
                            if (
                              value &&
                              getFieldValue("runAtTime") &&
                              setClientTz(
                                combineDateTime(
                                  value,
                                  getFieldValue("runAtTime")
                                )
                              ).isBefore(currentDateTime)
                            ) {
                              return Promise.reject(
                                "Run date & time should be in future!"
                              )
                            }
                            return Promise.resolve()
                          },
                        }),
                      ]}
                    >
                      <DatePicker />
                    </Form.Item>
                  </div>
                </div>

                <div tw="w-6/12 flex flex-row items-center">
                  <InputLabel
                    isRequired
                    customCss={tw`ml-4 mr-2 font-bold text-2xs!`}
                  >
                    {submitContent.runTime}
                  </InputLabel>
                  <div tw="flex items-center">
                    <Form.Item
                      name="runAtTime"
                      tw="w-full"
                      rules={[
                        {
                          required: true,
                          message: "Please input!",
                        },
                      ]}
                    >
                      <TimePicker use12Hours format="h:mm a" />
                    </Form.Item>
                  </div>
                  <div tw="ml-3 text-xs font-bold mr-2">
                    {getClientTZAbbreviation()}
                  </div>
                </div>
              </div>

              {/* email subject */}
              <div tw="flex flex-row py-3 w-full items-center">
                <InputLabel
                  isRequired
                  customCss={tw`w-3/12 font-bold text-2xs!`}
                >
                  {submitContent.emailSubject}
                </InputLabel>
                <div tw="flex items-center w-9/12">
                  <Form.Item
                    name="subject"
                    tw="mr-2 w-full"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please input email subject!",
                      },
                      {
                        whitespace: true,
                        message: "Please input email subject!",
                      },
                    ]}
                  >
                    <Input placeholder="Appointment Reminder from Park Cities Eye" />
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </CustomModal>
    </>
  )
}

export default SubmitCampaignModal
