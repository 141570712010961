import React from "react"
import tw, { css } from "twin.macro"
import { useQuery, useMutation } from "@apollo/client"
import { Tooltip, Collapse, Form, Input } from "antd"
import {
  InfoCircleOutlined,
  EditOutlined,
  EditFilled,
  CopyOutlined,
  DeleteFilled,
} from "@ant-design/icons"
import ContentEditable from "react-contenteditable"
import { navigate } from "gatsby"
import { Router } from "@reach/router"
import { useToasts } from "react-toast-notifications"
import he from "he"

import CampaignNavigation, {
  NavigationWidth,
} from "@src/components/campaign/navigation"
import CustomContainer from "@src/components/customContainer"
import Loading from "@src/components/loading"
import {
  GET_CAMPAIGNS,
  Campaign,
  CampaignStatus,
  UPDATE_CAMPAIGN,
  DELETE_CAMPAIGN,
  CANCEL_CAMPAIGN_SEND,
} from "@src/queries/campaign"
import CustomButton, { ButtonColors } from "@src/components/ui/button"
import ErrorComponent from "@src/components/ui/error"
import { createUrl } from "@src/utils/createUrl"
import {
  navigationRoutes,
  PageOptions,
} from "@src/localization/en/createCampaign"
import Status from "@src/sections/campaigns/status"
import SubmitCampaignModal from "@src/sections/campaigns/submit"
import SubmittedCampaignModal from "./submitted"
import { Localization } from "@src/localization"
import { GET_TEMPLATES, Template } from "@src/queries/template"
import config from "@src/config"
import { getToken } from "@src/utils/auth"
import CustomToolTip from "@src/components/ui/customToolTip"
import { getFormErrors } from "@src/utils/catchErrors"
import { serverToClient } from "@src/utils/timezone"

const { Panel } = Collapse

export enum StatColumn {
  SENT = "sent",
  DELIVERED = "delivered",
  OPENED = "opened",
  CLICKED = "clicked",
  BOUNCED = "bounced",
  UNSUBSCRIBED = "unsubscribed"
}

const emailStats = [
  {
    key: "statsSent",
    showPercent: false,
    responseKey: "accepted",
    responseField: "total",
    insideStats: true,
    column: StatColumn.SENT
  },
  {
    key: "statsDelivered",
    showPercent: true,
    responseKey: "delivered",
    responseField: "total",
    insideStats: true,
    column: StatColumn.DELIVERED
  },
  {
    key: "statsOpened",
    showPercent: true,
    responseKey: "opened",
    responseField: "unique",
    insideStats: true,
    column: StatColumn.OPENED
  },
  {
    key: "statsClicked",
    showPercent: true,
    responseKey: "clicked",
    responseField: "unique",
    insideStats: true,
    column: StatColumn.CLICKED
  },
  {
    key: "statsBounces",
    showPercent: true,
    responseKey: "failed",
    responseSubKeys: ["temporary", "permanent"],
    responseField: "total",
    insideStats: true,
    column: StatColumn.BOUNCED
  },
  {
    key: "statsUnsubscribers",
    showPercent: true,
    responseKey: "unsubscribed",
    responseField: "",
    insideStats: false,
    column: StatColumn.UNSUBSCRIBED
  },
]

const Campaigns = (): React.ReactElement => {
  const { addToast } = useToasts()

  // active key
  const [activeKey, setActiveKey] = React.useState([])

  // is editing
  const [isEditingName, setIsEditingName] = React.useState<boolean>(false)

  // page content
  const campaignsContent = React.useContext(Localization).campaigns

  // get campaigns
  const getCampaignsResp = useQuery(GET_CAMPAIGNS, {
    fetchPolicy: "network-only",
  })

  // get templates
  const getTemplatesResp = useQuery(GET_TEMPLATES)

  // update campaign
  const [updateCampaignMutation, updateCampaignResp] = useMutation(
    UPDATE_CAMPAIGN
  )

  // delete campaign
  const [deleteCampaignMutation, deleteCampaignResp] = useMutation(
    DELETE_CAMPAIGN
  )

  // cancel campaign send
  const [cancelCampaignMutation, cancelCampaignResp] = useMutation(
    CANCEL_CAMPAIGN_SEND
  )

  // form
  const [form] = Form.useForm()

  // loading
  if (getCampaignsResp.loading || getTemplatesResp.loading)
    return <Loading withOverlay={true} />

  // show error page
  if (getCampaignsResp.error || getTemplatesResp.error)
    return (
      <ErrorComponent msg="There is an error fetching data. Please refresh your page or try again later." />
    )

  // sort campaigns
  let sortedCampaigns: Campaign[] =
    (getCampaignsResp.data && getCampaignsResp.data.campaigns) || []
  if (sortedCampaigns && sortedCampaigns.length > 0) {
    sortedCampaigns = sortedCampaigns
      .slice()
      .sort((a: Campaign, b: Campaign) => {
        return a.modifiedAt > b.modifiedAt ? -1 : 1
      })
  }

  // templates
  const templates: Template[] =
    (getTemplatesResp.data && getTemplatesResp.data.templates) || []

  // update campaign
  const updateCampaign = async ({ campaign }: { campaign: Campaign }) => {
    // update campaign
    await updateCampaignMutation({
      variables: {
        campaignId: campaign.id,
        body: {
          ...campaign,
        },
      },
    })
  }

  // delete campaign
  const deleteCampaign = async (campaignId: number) => {
    await deleteCampaignMutation({
      variables: {
        campaignId,
      },
      refetchQueries: [
        {
          query: GET_CAMPAIGNS,
        },
      ],
    })
  }

  // cancel campaign
  const cancelCampaign = async (campaignId: number) => {
    await cancelCampaignMutation({
      variables: {
        campaignId,
      },
      refetchQueries: [
        {
          query: GET_CAMPAIGNS,
        },
      ],
    })
  }

  return (
    <CustomContainer customCss={tw`bg-white`}>
      {(updateCampaignResp.loading ||
        deleteCampaignResp.loading ||
        cancelCampaignResp.loading) && <Loading withOverlay={true} />}

      <div css={[tw`flex flex-col w-full`]}>
        {/* Campaign navigation */}
        <CampaignNavigation width={NavigationWidth.ThreeNine} />

        {/* Submit campaign modal */}
        <Router>
          <SubmitCampaignModal
            path={navigationRoutes[PageOptions.SUBMITCAMPAIGN]}
          />
          <SubmittedCampaignModal
            path={navigationRoutes[PageOptions.SUBMITTEDCAMPAIGN]}
          />
        </Router>

        <div tw="flex flex-col self-center w-11/12">
          <div tw="flex text-xs text-gray-800 text-center uppercase">
            <div tw="w-5/12 pl-24 text-left">{campaignsContent.campaigns}</div>
            <div tw="w-2/12">{campaignsContent.status}</div>
            <div tw="w-5/12">{campaignsContent.action}</div>
          </div>
        </div>

        <div tw="flex flex-col self-center w-11/12 min-h-md bg-white border border-gray-400 rounded-lg mb-4">
          <Form name="campaigns" form={form}>
            <Collapse
              accordion
              activeKey={activeKey}
              onChange={(key: any) => {
                // activate
                setActiveKey(key)
                setIsEditingName(false)
              }}
              css={[
                tw`flex flex-col self-center w-full text-black bg-white border-none rounded-lg`,
                css`
                  & .ant-collapse-header {
                    padding: 0px !important;
                  }
                  & .ant-collapse-content-box {
                    padding: 0px !important;
                  }
                `,
              ]}
            >
              {sortedCampaigns.map((oneCampaign: Campaign) => {
                // name change required
                const isNameChangeReq = oneCampaign.name
                  .toLowerCase()
                  .includes("- copy")

                // get template name
                const template = templates.find(
                  t => t.id === parseInt(oneCampaign.selectedTemplateId)
                )
                const templateName = template ? template.name : "-"

                // can delete campaign
                const canDeleteCampaign = [CampaignStatus.Draft].includes(
                  oneCampaign.status
                )

                // can cancel campaign
                const canCancelCamapign = [CampaignStatus.Submitted].includes(
                  oneCampaign.status
                )

                return (
                  <Panel
                    showArrow={false}
                    key={oneCampaign.id}
                    header={
                      <div tw="flex w-full">
                        {/* campaign details */}
                        <div tw="flex flex-col w-5/12 py-3 px-3">
                          <div tw="font-bold">
                            {campaignsContent.campaign}: {oneCampaign.name}
                          </div>
                          <div tw="text-xs text-gray-800">
                            {campaignsContent.template}:{" "}
                            <span tw="font-bold text-black">
                              {templateName}
                            </span>
                          </div>
                          <div tw="text-xs text-gray-800">
                            {campaignsContent.edited}{" "}
                            <span tw="font-bold text-black">
                              {serverToClient(oneCampaign.modifiedAt).format(
                                "llll z"
                              )}
                            </span>{" "}
                          </div>
                          {oneCampaign.status !== CampaignStatus.Draft &&
                            oneCampaign.runAt && (
                              <div tw="text-xs text-gray-800">
                                {campaignsContent.scheduled}
                                {": "}
                                <span tw="font-bold text-black">
                                  {serverToClient(oneCampaign.runAt).format(
                                    "llll z"
                                  )}
                                </span>
                              </div>
                            )}
                        </div>

                        {/* status */}
                        <div tw="flex w-2/12 items-center justify-center border border-t-0 border-b-0 border-gray-400">
                          <Status status={oneCampaign.status} />
                        </div>

                        {/* action items */}
                        <div
                          css={[
                            tw`flex w-5/12 items-center justify-around relative`,
                          ]}
                        >
                          <div tw="flex items-center">
                            {/* cancel send button */}
                            <CustomButton
                              disabled={!canCancelCamapign}
                              color={ButtonColors.white}
                              customCss={tw`ml-2! px-4!`}
                              onClick={e => {
                                e.stopPropagation()
                                canCancelCamapign &&
                                  cancelCampaign(oneCampaign.id)
                              }}
                            >
                              {campaignsContent.cancelSend}
                            </CustomButton>
                          </div>

                          {/* duplicate button */}
                          <div tw="flex items-center">
                            <CustomButton
                              color={ButtonColors.white}
                              customCss={tw`px-4!`}
                              onClick={e => {
                                e.stopPropagation()
                                navigate(
                                  createUrl(
                                    navigationRoutes[
                                      PageOptions.DUPLICATECAMPAIGNPICKSEGMENT
                                    ],
                                    {
                                      campaignId: oneCampaign.id,
                                    }
                                  )
                                )
                              }}
                            >
                              <CopyOutlined tw="mr-2 text-base text-gray-700" />
                              {campaignsContent.duplicate}
                            </CustomButton>
                            <Tooltip
                              placement="topRight"
                              title={campaignsContent.duplicateInfo}
                              overlayStyle={{
                                fontSize: "0.8rem",
                              }}
                              css={tw`ml-1`}
                              arrowPointAtCenter
                            >
                              <InfoCircleOutlined />
                            </Tooltip>
                          </div>

                          {/* edit button */}
                          <CustomButton
                            color={ButtonColors.white}
                            customCss={tw`px-4!`}
                          >
                            <EditFilled tw="mr-2 text-base text-gray-700" />
                            {campaignsContent.edit}
                          </CustomButton>

                          {canDeleteCampaign && (
                            <div
                              tw="absolute border border-l-0! border-gray-400 rounded-r-lg bg-white"
                              css={css`
                                right: -45px;
                                width: 45px;
                              `}
                            >
                              {/* delete button */}
                              <DeleteFilled
                                title={campaignsContent.delete}
                                css={[
                                  tw`text-lg mx-1 px-2 py-3`,
                                  canDeleteCampaign
                                    ? tw`text-gray-700`
                                    : tw`text-gray-400 cursor-not-allowed!`,
                                ]}
                                onClick={e => {
                                  e.stopPropagation()
                                  canDeleteCampaign &&
                                    deleteCampaign(oneCampaign.id)
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    }
                  >
                    <div tw="flex flex-col text-black">
                      {[
                        CampaignStatus.Draft,
                        CampaignStatus.Submitted,
                      ].includes(oneCampaign.status) && (
                        <>
                          {/* campaign name */}
                          <div tw="flex flex-col py-2">
                            <div tw="flex px-6 py-2 items-center w-full">
                              <div tw="text-sm pr-4 font-bold">
                                {campaignsContent.campaignName}:
                              </div>
                              <div tw="text-gray-800 flex-grow">
                                <Form.Item
                                  name={[oneCampaign.id, "name"].join(".")}
                                  css={tw`hidden!`}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input campaign name!",
                                    },
                                    {
                                      whitespace: true,
                                      message: "Please input campaign name!",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                                <ContentEditable
                                  placeholder="Park Cities Eye Associates"
                                  html={oneCampaign.name} // innerHTML of the editable div
                                  disabled={!isEditingName} // use true to disable edition
                                  onChange={e => {
                                    form.setFieldsValue({
                                      [[oneCampaign.id, "name"].join(
                                        "."
                                      )]: he.decode(e.target.value),
                                    })
                                  }}
                                  style={{ outline: "none" }}
                                  css={[
                                    tw`border`,
                                    isEditingName
                                      ? tw`border-gray-500`
                                      : tw`border-transparent`,
                                  ]}
                                />
                              </div>
                              {[CampaignStatus.Draft].includes(
                                oneCampaign.status
                              ) && (
                                <>
                                  {!isEditingName ? (
                                    <CustomButton
                                      color={ButtonColors.white}
                                      onClick={() => {
                                        setIsEditingName(true)
                                      }}
                                    >
                                      <EditOutlined tw="mr-2" />
                                      {campaignsContent.editName}
                                    </CustomButton>
                                  ) : (
                                    <CustomButton
                                      color={ButtonColors.orange}
                                      onClick={async () => {
                                        try {
                                          // validate form
                                          await form.validateFields()

                                          // update campaign name
                                          updateCampaign({
                                            campaign: {
                                              ...oneCampaign,
                                              name: form.getFieldValue(
                                                [oneCampaign.id, "name"].join(
                                                  "."
                                                )
                                              ),
                                            },
                                          })
                                          setIsEditingName(false)
                                        } catch (e) {
                                          const errors = getFormErrors(e)
                                          errors &&
                                            addToast(errors[0], {
                                              appearance: "error",
                                              autoDismiss: true,
                                            })
                                        }
                                      }}
                                    >
                                      {campaignsContent.saveName}
                                    </CustomButton>
                                  )}
                                </>
                              )}
                            </div>
                            {isNameChangeReq && (
                              <div tw="flex text-red-600 w-full px-6 justify-end text-sm">
                                {campaignsContent.requiredChangeName}
                              </div>
                            )}
                          </div>

                          {/* campaign templates */}
                          <div tw="flex px-6 py-4 items-center border-t border-gray-300">
                            <div tw="text-sm pr-4 font-bold">
                              {campaignsContent.campaignTemplate}:
                            </div>
                            <div tw="text-gray-800 flex-grow">
                              {templateName}
                            </div>

                            {[CampaignStatus.Draft].includes(
                              oneCampaign.status
                            ) && (
                              <>
                                <CustomButton
                                  color={ButtonColors.white}
                                  onClick={() => {
                                    navigate(
                                      createUrl(
                                        navigationRoutes[PageOptions.EDITOR],
                                        {
                                          campaignId: oneCampaign.id,
                                        }
                                      )
                                    )
                                  }}
                                >
                                  <EditOutlined tw="mr-2" />{" "}
                                  {campaignsContent.editTemplate}
                                </CustomButton>
                              </>
                            )}
                          </div>
                        </>
                      )}

                      {[CampaignStatus.Ran].includes(oneCampaign.status) && (
                        <>
                          <div tw="flex flex-row py-4 px-6 w-full">
                            <div tw="flex flex-row w-full">
                              {emailStats.map(oneField => {
                                return (
                                  <div
                                    key={oneField.key}
                                    tw="flex w-2/12 p-2 mx-2 border border-gray-300 flex-col items-center"
                                    css={css`
                                      box-shadow: 0 0 5px 0 #00000040;
                                    `}
                                  >
                                    {/* stats heading */}
                                    <div tw="text-2xs uppercase">
                                      {(campaignsContent as any)[oneField.key]}
                                    </div>

                                    {/* stats percent */}
                                    {oneField.showPercent ? (
                                      <div tw="text-lg font-bold">
                                        {oneCampaign.sent !== 0 ? ((oneCampaign[oneField.column] / oneCampaign.sent) * 100).toFixed(2) : 0}%
                                      </div>
                                    ) : (
                                      <div tw="text-lg font-bold h-2" />
                                    )}

                                    {/* stats total */}
                                    <div
                                      css={
                                        oneField.showPercent
                                          ? tw`text-2xs`
                                          : tw`text-lg font-bold`
                                      }
                                    >
                                      {(oneCampaign[oneField.column])}
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                            <CustomToolTip
                              title={
                                <div>
                                  {campaignsContent.statsInfo.map(oneInfo => (
                                    <div tw="flex w-full" key={oneInfo.key}>
                                      <div tw="font-bold mr-2 w-32">
                                        {oneInfo.key}:
                                      </div>
                                      <div tw="">{oneInfo.description}</div>
                                    </div>
                                  ))}
                                </div>
                              }
                              overlayStyle={{
                                maxWidth: 900,
                              }}
                              customCss={tw`pt-0!`}
                            />
                          </div>

                          <div tw="flex flex-row pb-4 px-6 w-full justify-end items-center">
                            {/* horizontalline */}
                            <div tw="border-t border-gray-400 flex-grow" />

                            {/* download patients */}
                            <CustomButton
                              color={ButtonColors.white}
                              customCss={tw`font-bold! ml-4`}
                              onClick={() => {
                                window.open(
                                  `${config.api_base_url}/v1/campaigns/${
                                    oneCampaign.id
                                  }/patients/download?sessionid=${getToken()}`
                                )
                              }}
                            >
                              {campaignsContent.downloadPatients}
                            </CustomButton>
                            <CustomToolTip
                              title={campaignsContent.downloadPatientsInfo}
                              customCss={tw`pt-0! ml-2`}
                            />
                          </div>
                        </>
                      )}

                      {![CampaignStatus.Ran].includes(oneCampaign.status) && (
                        <>
                          <div tw="flex h-16 border-t border-gray-300 w-full"></div>

                          {/* action items */}
                          <div tw="flex justify-end border-t border-gray-300 px-6 py-4">
                            <CustomButton
                              color={ButtonColors.white}
                              customCss={tw`mr-2 uppercase`}
                              onClick={() => setActiveKey([])}
                            >
                              {campaignsContent.close}
                            </CustomButton>

                            {/* submit/re-submit button */}
                            {[CampaignStatus.Draft].includes(
                              oneCampaign.status
                            ) && (
                              <CustomButton
                                color={ButtonColors.orange}
                                customCss={tw`mr-2 uppercase`}
                                disabled={isNameChangeReq}
                                onClick={() => {
                                  navigate(
                                    createUrl(
                                      navigationRoutes[
                                        PageOptions.SUBMITCAMPAIGN
                                      ],
                                      {
                                        campaignId: oneCampaign.id,
                                      }
                                    )
                                  )
                                }}
                              >
                                {campaignsContent.submit}
                              </CustomButton>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </Panel>
                )
              })}
            </Collapse>
          </Form>
          <div tw="px-4 py-8 text-center">
            {campaignsContent.noOtherCampaigns}
          </div>
        </div>
      </div>
    </CustomContainer>
  )
}

export default Campaigns
