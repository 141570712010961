import React from "react"
import tw from "twin.macro"
import { navigate } from "gatsby"
import { RouteComponentProps } from "@reach/router"

import CustomModal from "@src/components/ui/modal"
import {
  navigationRoutes,
  PageOptions,
} from "@src/localization/en/createCampaign"
import { Localization } from "@src/localization"
import CustomButton, { ButtonColors } from "@src/components/ui/button"
import CustomToolTip from "@src/components/ui/customToolTip"

const Title = ({ heading }: { heading: string }) => (
  <CustomModal.Title heading={heading} alignCenter>
    {" "}
  </CustomModal.Title>
)

const Footer = ({
  heading,
  toolTipMsg,
}: {
  heading: string
  toolTipMsg: string
}) => (
  <CustomModal.Footer
    heading={heading}
    customCss={tw`px-6!`}
    headingCss={tw`text-black! font-normal! text-2xs!`}
  >
    <div>
      <CustomToolTip title={toolTipMsg} placement="top" customCss={tw`pt-0!`} />
    </div>
    <div tw="flex justify-end ml-6">
      <CustomButton
        color={ButtonColors.white}
        onClick={() => navigate(navigationRoutes[PageOptions.CAMPAIGNS])}
      >
        CLOSE
      </CustomButton>
    </div>
  </CustomModal.Footer>
)

// eslint-disable-next-line no-empty-pattern
const SubmittedCampaignModal = ({}: RouteComponentProps): React.ReactElement => {
  // page content
  const submitContent = React.useContext(Localization).submitCampaign

  return (
    <CustomModal
      title={<Title heading={submitContent.submittedHeading} />}
      isVisible={true}
      onCancel={() => navigate(navigationRoutes[PageOptions.CAMPAIGNS])}
      width={400}
      footer={
        <Footer
          heading={submitContent.submittedFooterHeading}
          toolTipMsg={submitContent.submittedFooterTooltip}
        />
      }
    >
      <div tw="flex flex-col px-6 py-4 text-black">
        <p
          css={[tw`text-base text-center text-sm`]}
          dangerouslySetInnerHTML={{
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            __html: submitContent.submittedDescription,
          }}
        />
      </div>
    </CustomModal>
  )
}

export default SubmittedCampaignModal
